<template>
    <section class="row mx-0">
        <div class="col-8 d-flex bg-white br-8 pb-3">
            <div class="col-auto pt-2">
                <el-checkbox v-model="datosBasicos.todo_catalogo" :true-label="1"
                             :false-label="0"
                             class="check-red"
                             @change="cambiarTodoCatalogo($event,'todo_catalogo')"
                >
                    Todo el catálogo
                </el-checkbox>
            </div>
            <div v-show="datosBasicos.todo_catalogo === 0" class="col position-relative">
                <div class="row mx-0">
                    <div class="col-8 pl-4 pr-0">
                        <p class="pl-3 text-general f-15">
                            Incluir categoría
                        </p>
                        <el-select v-model="valueCategoria" placeholder="Seleccionar" size="small" class="w-100" @change="agregarCategoria($event)">
                            <el-option
                            v-for="c in categorias"
                            :key="c.id"
                            :label="c.nombre"
                            :value="c.id"
                            :disabled="c.disabled"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-for="(cat,index) in categoriasAplicadas" :key="index" class="row mx-0 my-3 align-items-center">
                    <div class="col-8 pl-4 pr-0">
                        <div class="d-middle border bg-whitesmoke2 px-2 py-1 br-4 f-14 text-general" style="height:32px;">
                            {{ cat.categoria }}
                            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                <i class="icon-cancel ml-auto cr-pointer f-18" @click="eliminarCategoria(cat)" />
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- <div class="col-auto d-middle px-0">
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    props:{
        idCupon:{
            type:Number,
            required:true
        },
        datosBasicos:{
            type:Object,
            required:true
        }
    },
    data(){
        return {
            valueCategoria:null,
            categorias:[],
            categoriasAplicadas:[]
        }
    },
    // computed:{
    //     ...mapGetters({
    //         datosBasicos: 'cupones/cupones/datosBasicos',
    //     }),
    //     // id_cupon(){
    //     //     return Number(this.$route.params.id_cupon);
    //     // }
    // },
    mounted(){
        this.getCategorias()
    },
    methods:{
        async getCategorias(){
            try {
                const {data} = await Cupones.getCategorias()
                this.categorias = data.categorias
                this.getCategoriasCupon()
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCategoriasCupon(){
            try {
                const {data} = await Cupones.getCategoriasCupon(this.idCupon)
                this.categoriasAplicadas = data.data
                this.categorias.map(c=>{
                    let find = this.categoriasAplicadas.find(o=>o.id_categoria == c.id)
                    if(find){
                        c.disabled = true
                    }
                })
            } catch (e){
                this.error_catch(e)
            }
        },
        async agregarCategoria(cat){
            try {
                let form = {
                    id_categoria:cat,
                    id_cupon:this.idCupon
                }
                await Cupones.agregarCategoria(this.idCupon, form)
                this.valueCategoria = null
                this.getCategoriasCupon();
                
                setTimeout(() => {
                    this.moverAbajo();
                }, 100);

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarTodoCatalogo(value,campo){
            try {
                let form = {
                    columna:campo,
                    value
                }
                const {data} = await Cupones.cambiarCondiciones(this.idCupon,form)
                //this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarCategoria(item){
            try {

                await Cupones.eliminarCategoria(item.id)
                this.getCategoriasCupon()

                let index = this.categorias.findIndex(o=>o.id === item.id_categoria)
                if(index > -1){
                    this.categorias[index].disabled = false
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        moverAbajo(){
            let elementDiv = document.getElementById('cont-scroll');
            const VALUE = elementDiv.scrollHeight - elementDiv.clientHeight;
            elementDiv.scrollTo({
                top: VALUE,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>
